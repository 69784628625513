import Link from 'next/link';
import styled from 'styled-components';
import { getUserLink } from '~/graphql';
import useTheme from '~/hooks/useTheme';
import { User } from '~/types';
import { shortenAddress } from '~/utils';
import FlexRow_ from './FlexRow';
import FollowButton from './FollowButton';
import TextLight from './TextLight';
import TzProfileBadge from './TzProfileBadge';
import UserAvatar from './UserAvatar';
import UserPopover from './UserPopover';

const FlexRow = styled(FlexRow_)`
  align-items: flex-start;
`;

const UserItemContainer = styled(FlexRow)`
  cursor: pointer;
  padding: 10px;
  overflow: hidden;
  white-space: pre-line;
  word-break: break-word;
  @media (hover: hover) {
    &:hover {
      text-decoration: none;
      background-color: ${({ theme }) => theme.colors.opacity};
    }
  }
`;

const UserTzProfileBadge = styled(TzProfileBadge)`
  position: relative;
  left: 6px;
  top: 2px;
`;

const CollectorCount = styled.div`
  color: ${({ theme }) => theme.colors.primary};
`;

const UserItem: React.FC<User & {
  collectedCount?: number
}> = ({
  collectedCount,
  ...user
}) => {
  const {
    name,
    address,
    description,
    metadata,
    isBlocked,
  } = user;
  const theme = useTheme();
  return (
    <Link href={ getUserLink({ name, address }) }>
      <UserItemContainer>
        <div style={ { marginRight: 15 } }>
          <UserPopover user={ user }>
            <div>
              <UserAvatar
                user={ user }
                sizes={
                  {
                    mobile: 50, fallback: 50,
                  }
                }
              />
            </div>
          </UserPopover>
        </div>
        <div style={ { flex: 1 } }>
          <FlexRow>
            <div style={ { flex: 1 } }>
              <UserPopover user={ user }>
                <div style={ { display: 'table' } }>
                  <div>
                    <a style={ { fontWeight: 'bold', marginTop: 0, marginBottom: 0 } }>
                      { name }
                    </a>
                    {
                      name ? (
                        <UserTzProfileBadge address={ user.address } />

                      ) : null
                    }
                  </div>
                  <TextLight as="a" style={ { marginTop: -2, marginBottom: 2 } }>
                    { shortenAddress(address) }
                    {
                      isBlocked ? (
                        <span style={ { color: theme.colors.alert, marginLeft: 10 } }>
                          blocked wallet
                        </span>
                      ) : null
                    }
                  </TextLight>
                </div>
              </UserPopover>
              <div>{metadata?.description || description}</div>
              {
                collectedCount ? (
                  <CollectorCount>{ `${collectedCount} objkt${collectedCount > 1 ? 's' : ''} collected`}</CollectorCount>
                ) : null
              }
            </div>
            <FollowButton address={ address } />
          </FlexRow>
        </div>
      </UserItemContainer>
    </Link>
  );
};

export default UserItem;
