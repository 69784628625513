import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { shortenAddress } from '~/utils';
import { getUserLink } from '~/graphql';
import _TzProfileBadge from './TzProfileBadge';
import { User } from '~/types';
import UserAvatar from './UserAvatar';
import FlexRow from './FlexRow';
import UserPopover from './UserPopover';
import { ADDRESSES } from '~/utils/addresses';
import Emoji from './Emoji';
import useUser from '~/hooks/useUser';

const Container = styled(FlexRow)`
  display: inline-flex;
`;

const TzProfileBadge = styled(_TzProfileBadge)`
  margin-left: 5px;
`;

const StyledUserAvatar = styled(UserAvatar)`
  margin-right: 5px;
  border: ${({ theme }) => theme.colors.borderXLight} solid thin;
`;

// const [visible, setVisible] = React.useState(false);
// trigger="hover"
// visible={ visible }
// onHide={ () => setVisible(false) }
// content="yo"
// render={ (attrs) => <div { ...attrs }>yo</div> }
// onMouseEnter={ () => setVisible(true) }
// onMouseLeave={ () => setVisible(false) }

export type UserTableItemProps = {
  user: User;
  noAvatar?: boolean;
  className?: string;
};

const UserTableItem: React.FC<UserTableItemProps> = ({
  user,
  noAvatar,
  className,
}) => (
  <Container className={ className }>
    {
      !user ? '-' : user.address === ADDRESSES.burn ? (
        <div style={ { fontWeight: 'bold' } }>
          <Emoji v="🔥" style={ { marginLeft: 3, marginRight: 4 } } />
          <span>BURN ADDRESS</span>
        </div>
      ) : (
        <>
          <UserPopover user={ user }>
            <div tabIndex={ -1 }>
              <Link href={ getUserLink(user) } passHref>
                <FlexRow as="a">
                  {
                    noAvatar ? null : (
                      <StyledUserAvatar user={ user } sizes={ { fallback: 20 } } />
                    )
                  }
                  <span style={ { fontWeight: 'bold' } }>
                    {user?.name || shortenAddress(user?.address) || '-'}
                  </span>
                </FlexRow>
              </Link>
            </div>
          </UserPopover>
          <TzProfileBadge address={ user?.address } />
        </>
      )
    }
  </Container>
);

export default UserTableItem;

export const UserTableItemByAddress: React.FC<Omit<UserTableItemProps, 'user'> & { address: string }> = ({ address, ...props }) => {
  const { data: user } = useUser(address);
  return (
    <UserTableItem user={ user } { ...props } />
  );
};
