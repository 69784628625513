import styled from 'styled-components';
import { media } from '~/styles/media';

export const MenuContainer = styled.div`
    border-bottom: ${({ theme }) => theme.colors.borderXLight} solid thin;
`;

export const MenuLink = styled.a<{ $active: boolean; disabled?: boolean; }>`
    display: inline-block;
    padding: 10px 15px;
    border-bottom: transparent solid 1px;
    border-bottom-color: ${({ $active, theme }) => ($active ? theme.colors.primary : 'transparent')};    
    color: ${({ $active, theme }) => ($active ? theme.colors.text : theme.colors.textLight)};
    font-weight: bold;
    margin-bottom: -1px;
    transition: background-color 200ms ease;
    &:hover {
        text-decoration: none;
        background-color: ${({ theme }) => theme.colors.opacity};
    }
    ${({ $active, theme }) => media.mobile`
        margin-bottom: 0;
        padding: 5px 10px 3px 10px;
        display: block;
        border-bottom-color: transparent;
        background-color: ${$active ? theme.colors.foreground : 'transparent'};    
    `}
    ${({ disabled }) => disabled && `
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
    `}
`;
