import React from 'react';

function useShowMore<T>(rows: T[], rpp: number = 20) {
  const [limit, setLimit] = React.useState<number>(rpp);
  const limitedRows = rows.slice(0, limit);
  const canShowMore = limit < rows.length;
  const showMore = () => setLimit((l) => l + rpp);
  React.useEffect(() => {
    setLimit(rpp);
  }, [JSON.stringify(rows), rpp]);
  return {
    limit,
    limitedRows,
    canShowMore,
    showMore,
  };
}

export default useShowMore;
