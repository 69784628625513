import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { useDataContext } from '~/contexts/Data';
import { tokenFragment, transformToken } from '~/graphql';
import { Objkt } from '~/types';
import useGraphqlClient from './useGraphqlClient';

const ObjktsQuery = gql`
  query Objkts(
    $ids: [bigint!],
    $offset: Int,
  ) {
    token(
      offset: $offset,
      where: {
        id: {
          _in: $ids
        }
      },
      order_by: {
        id: desc
      }
    ) {
      ${tokenFragment} 
    }
  }
`;

export const getObjkts = async ({
  ids,
  blockedWallets,
  client,
}: {
  ids: string[];
  blockedWallets?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client: any;
}) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { token = [] } = await client(
    ObjktsQuery,
    { ids },
  );
  return token.map((t) => transformToken(t, blockedWallets));
};

const useObjkts = (ids: string[]) => {
  const { blockedWallets } = useDataContext();
  const client = useGraphqlClient();
  return useQuery<Objkt[]>(
    ['objkts', ...ids.sort()],
    () => getObjkts({ ids, blockedWallets, client }),
    { enabled: ids.length > 0 },
  );
};

export default useObjkts;
