import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { MenuContainer, MenuLink } from '~/components/Menu';
import useBreakpoint from '~/hooks/useBreakpoint';
import { Breakpoints } from '~/styles/media';
import Select from './Select';

type MenuSection = {
  label: string;
  url: string;
  onClick?: () => void;
  isActive: boolean;
};

type Props = {
  label?: string;
  sections: MenuSection[];
  className?: string;
};

const MenuLabel = styled.label`
  margin-bottom: 5px;
  display: block;
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.textLight};
`;

const SelectContainer = styled.div`
  width: 100%;
  > div, select {
    width: 100%;
  }
`;

const StyledMenuContainer = styled(MenuContainer)`
  margin: 0 -15px;
`;

const MenuNav: React.FC<Props> = ({
  label: menuLabel,
  sections = [],
  className,
}) => {
  const router = useRouter();
  const breakpoint = useBreakpoint();
  const section = sections.find(({ isActive }) => isActive);
  return breakpoint === Breakpoints.mobile ? (
    <SelectContainer className={ className }>
      {
        menuLabel ? (
          <MenuLabel>{ menuLabel }</MenuLabel>
        ) : null
      }
      <Select
        value={ section?.url }
        onChange={ (e) => router.push(e.target.value, e.target.value, { shallow: true }) }
      >
        {
          sections.map(({ label, url }) => (
            <option key={ `menu.select.${url}` } value={ url }>
              { label }
            </option>
          ))
        }
      </Select>
    </SelectContainer>
  ) : (
    <StyledMenuContainer className={ className }>
      {
        sections.map(({ label, url, isActive }) => (
          <Link key={ `menu.${url}` } href={ url } passHref>
            <MenuLink $active={ isActive }>{ label }</MenuLink>
          </Link>
        ))
      }
    </StyledMenuContainer>
  );
};

export default MenuNav;
